import authorization from '@/api/authorization'

export const authenticate = async (data) => {
  const token = await authorization.authenticate(data)
  return token
}

export default {
  authenticate
}
