<template>
  <div class="parent">
    <div class="child">
      <div class="container">
        <card-component title="Login" icon="lock">
          <figure class="image">
            <img src="@/assets/black-mrrubbishman.png">
          </figure>
          <br>
          <form @submit.prevent="submit">
            <b-field label="Username">
              <b-input
                minlength="3"
                v-model="user.username"
                icon="account"
                name="username"
                required
              />
            </b-field>
            <b-field label="Password">
              <b-input
                minlength="8"
                type="password"
                v-model="user.password"
                icon="lock"
                name="password"
                required
              />
            </b-field>
            <hr />
            <b-field>
              <div class="control">
                <b-button native-type="submit" type="is-dark"
                  >Login</b-button
                >
              </div>
            </b-field>
          </form>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import authorization from '@/services/authorization'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      user: {}
    }
  },
  created () {
    if (this.$route.query.error === 'unauthorized') {
      this.$buefy.snackbar.open({
        message: 'Unauthorized! Please login again.',
        queue: false
      })
    }
  },
  methods: {

    async submit () {
      try {
        const data = await authorization.authenticate(this.user)
        localStorage.setItem('token', data.token)
        this.$router.push('/')
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    }
  }
}
</script>

<style scoped>
  .parent {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  .child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .container {
    width: 500px;
  }
</style>
