<template>
  <div class="parent">
    <div class="child">
      <div class="container">
        <card-component>
          <figure class="image">
            <img src="@/assets/black-mrrubbishman.png">
            </figure>
          <br>
          <div class="notfound-container">
            <h1 class="title">Page not found</h1>
            <b-button @click="goBack" type="is-dark">Go back</b-button>
          </div>
        </card-component>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'

export default {
  name: 'Login',
  components: { CardComponent },
  methods: {
    goBack () {
      this.$router.go(-2)
    }
  }
}
</script>

<style scoped>
  .parent {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
  .child {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
  }
  .container {
    width: 500px;
  }
  .notfound-container {
    text-align: center;
  }
</style>
